<template>
  <div class="news-search-scroll">
    <news-list
      :loadData="loadNews"
      :actionHandler="listActionHandler"
      :actionTrigger="listActionTrigger"
      newsItemUniqueClass="news-item-search"
      parentElementClass="news-search-scroll"
      name="news-search-list"
      ref="newsSearchList"
    >
      <template v-slot:no-result>
        <no-result
          :title="lang.search_page.no_news_found.title"
          :description="lang.search_page.no_news_found.description"
        >
          <template v-slot:image>
            <img
              src="../../../assets/img/no-news.png"
              alt=""
              srcset=""
            />
          </template>
        </no-result>
      </template>
      <template v-slot:network-error>
        <network-error :clickHandler="hardReset"></network-error>
      </template>
    </news-list>
  </div>
</template>


<script>
import NoResult from "./NoResult.vue";
import NetworkError from "./NetworkError.vue";
import NewsList from "./NewsList.vue";

export default {
  inject: ["store"],
  props: ["searchTerm", "searchTermUpdate", "searchTrigger"],
  data() {
    return {
      listActionTrigger: false,
      listActionHandler: null,
    };
  },
  components: {
    NewsList,
    NoResult,
    NetworkError,
  },

  methods: {
    loadNews(start, size) {
      let self = this;

      let data = {
        start: start,
        slice: size,
        searchTerm: self.searchTerm,
      };

      return window.axios({
        url: self.store.endpoint + "/search-news",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${self.store.getters.userToken()}`,
        },
      });
    },

    triggerListAction() {
      this.listActionTrigger = !this.listActionTrigger;
    },

    softReset() {
      this.listActionHandler = (self) => {
        self.softReset(true);
      };
      this.triggerListAction();
    },

    hardReset() {
      this.listActionHandler = (self) => {
        self.hardReset();
      };
      this.triggerListAction();
    },
  },

  watch: {
    searchTermUpdate() {
      if (this.searchTrigger == "auto") {
        this.softReset();
      } else {
        this.hardReset();
      }
    },
  },

  mounted() {
    this.$refs.newsSearchList.$refs.recycler.init();
  },
};
</script>


<style scoped lang="scss">
.news-search-scroll {
  overflow: auto;
  position: relative;
  height: 100%;
}
</style>