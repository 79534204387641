<template>
  <!-- <base-layout :noPageHeader="noPageHeader" :toolbarClass="toolbarClass" :headerClass="headerClass" :pageDefaultBackLink="pageDefaultBackLink"> -->
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="null"
    :contentColor="'primary'"
  >
    <template v-slot:search-bar>
      <form @submit="submitForm">
        <ion-searchbar
          show-cancel-button="focus"
          animated="true"
          @ionChange="searchInput($event)"
          debounce="500"
          class="search"
          :placeholder="placeholder"
          ref="search"
        ></ion-searchbar>
      </form>
    </template>
    <template v-slot:ion-content>
      <div class="page-container ion-padding">
        <!-- <p class="search-type">
          <ion-chip
            :class="{ active: searchType == 'signals' }"
            @click="changeSearchType('signals')"
          >
            <ion-icon :icon="pulseSharp"></ion-icon>
            <ion-label>{{ lang.search_page.signals.signals }}</ion-label>
          </ion-chip>

          <ion-chip
            :class="{ active: searchType == 'news' }"
            @click="changeSearchType('news')"
          >
            <ion-icon :icon="newspaper"></ion-icon>
            <ion-label>{{ lang.search_page.signals.news }}</ion-label>
          </ion-chip>
        </p> -->

        <template v-if="searchType == 'signals'">
          <no-result
            v-if="searchTerm == ''"
            :title="lang.search_page.signals.instruction_title"
            :description="lang.search_page.signals.instruction_description"
          >
            <template v-slot:image>
              <img src="../../assets/img/no-result-home.png" alt="" srcset="" />
            </template>
          </no-result>

          <signal-search
            :searchTerm="searchTerm"
            :searchTrigger="searchTrigger"
            :searchTermUpdate="searchTermUpdate"
            v-else
          ></signal-search>
        </template>

        <template v-if="searchType == 'news'">
          <no-result
            v-if="searchTerm == ''"
            :title="lang.search_page.news.instruction_title"
            :description="lang.search_page.news.instruction_description"
          >
            <template v-slot:image>
              <img src="../../assets/img/no-news.png" alt="" srcset="" />
            </template>
          </no-result>

          <news-search
            :searchTerm="searchTerm"
            :searchTrigger="searchTrigger"
            :searchTermUpdate="searchTermUpdate"
            v-else
          ></news-search>
        </template>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import {
  IonSearchbar,
  // IonChip,
  // IonIcon,
  // IonLabel,
} from "@ionic/vue";
import { newspaper, pulseSharp } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import SignalSearch from "../components/cat-3/SignalSearch.vue";
import NoResult from "../components/cat-3/NoResult.vue";
import NewsSearch from "../components/cat-3/NewsSearch.vue";

export default {
  inject: ["store"],
  data() {
    return {
      newspaper,
      pulseSharp,
      searchTerm: "",
      searchTrigger: "auto",
      searchTermUpdate: false,
    };
  },
  components: {
    IonSearchbar,
    "base-layout-2": BaseLayout2,
    // IonLabel,
    // IonChip,
    // IonIcon,
    SignalSearch,
    NoResult,
    NewsSearch,
  },

  methods: {
    submitForm(ev) {
      ev.preventDefault();
      this.searchTrigger = "manual";
      this.searchTerm = ev.srcElement[0].value;
      this.searchTermUpdate = !this.searchTermUpdate;
    },

    searchInput(ev) {
      this.searchTrigger = "auto";
      this.searchTerm = ev.detail.value;
      this.searchTermUpdate = !this.searchTermUpdate;
    },

    changeSearchType(type) {
      this.store.setters.searchType(type);
    },
  },

  computed: {
    searchType() {
      return this.store.state.searchType;
    },

    placeholder() {
      if (this.searchType == "signals") {
        return this.lang.search_page.signals.placeholder;
      } else {
        return this.lang.search_page.news.placeholder;
      }
    },
  },

  ionViewDidEnter() {
    // console.log({ stype: this.searchType });
  },

  mounted() {
    let self = this;
    setTimeout(function () {
      self.$refs.search.$el.setFocus();
    }, 600);
  },
};
</script>


<style scoped lang="scss">
.page-container {
  padding-top: 20px;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  @include toolbar-content-size-1;
}

ion-searchbar.search {
  --border-radius: 20px;
}

// ion-list.search-type {
//   border-radius: 30px;
//   border-top-right-radius: 0px;
// }

p.search-type {
  margin-top: 0;
  position: relative;
  z-index: 2;

  ion-chip {
    border: 1px solid #ccc;
    font-weight: bold;
    background: transparent;

    ion-icon,
    ion-label {
      color: #888;
    }

    &.active {
      background: hsl(163.4, 56%, 87.1%);
      border-color: hsl(163.4, 56%, 47.1%);

      ion-icon,
      ion-label {
        color: hsl(163.4, 56%, 37.1%);
      }
    }
  }
}
</style>