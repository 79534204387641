<template>
  <div class="signal-search-scroll">
    <signal-list
      :loadData="loadSignals"
      :actionHandler="listActionHandler"
      :actionTrigger="listActionTrigger"
      parentElementClass="signal-search-scroll"
      signalItemUniqueClass="signal-item-search"
      name="signal-search-list"
      ref="signalSearchList"
      readLabel="seen"
    >
      <template v-slot:no-result>
        <no-result
          :title="lang.search_page.no_signals_found.title"
          :description="lang.search_page.no_signals_found.description"
        >
          <template v-slot:image>
            <img
              src="../../../assets/img/no-result-home.png"
              alt=""
              srcset=""
            />
          </template>
        </no-result>
      </template>
      <template v-slot:network-error>
        <network-error :clickHandler="hardReset"></network-error>
      </template>
    </signal-list>
  </div>
</template>


<script>
import SignalList from "../../components/cat-3/SignalList";
import NoResult from "../../components/cat-3/NoResult.vue";
import NetworkError from "../../components/cat-3/NetworkError.vue";

export default {
  inject: ["store"],
  props: ["searchTerm", "searchTermUpdate", "searchTrigger"],
  data() {
    return {
      listActionTrigger: false,
      listActionHandler: null,
    };
  },
  components: {
    SignalList,
    NoResult,
    NetworkError,
  },

  methods: {
    loadSignals(start, size) {
      let self = this;

      let data = {
        start: start,
        slice: size,
        searchTerm: self.searchTerm,
      };

      return window.axios({
        url: self.store.endpoint + "/search-signals",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${self.store.getters.userToken()}`,
        },
      });

    },

    triggerListAction() {
      this.listActionTrigger = !this.listActionTrigger;
    },

    softReset() {
      this.listActionHandler = (self) => {
        self.softReset(true);
      };
      this.triggerListAction();
    },

    hardReset() {
      this.listActionHandler = (self) => {
        self.hardReset();
      };
      this.triggerListAction();
    },
  },

  watch: {
    searchTermUpdate() {
      if (this.searchTrigger == "auto") {
        this.softReset();
      } else {
        this.hardReset();
      }
    },
  },

  mounted() {
    this.$refs.signalSearchList.$refs.recycler.init();
  },
};
</script>


<style scoped lang="scss">
.signal-search-scroll {
  overflow: auto;
  position: relative;
  height: 100%;
}
</style>